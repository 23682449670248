
body {
    background-color: #181818;
    color: #ddd;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.comparison-container {
    display: flex;
    justify-content: space-around;
    margin: 50px 0;
    flex-wrap: wrap;
}

.product-box {
    background-color: #242424;
    border: 2px solid #00ffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 255, 255, 0.2);
    width: 45%;
    margin: 15px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
}

.product-box:hover {
    transform: scale(1.05);
}

.product-box h2 {
    color: #00ffff;
    margin-bottom: 15px;
}

.product-box .price {
    font-size: 1.5em;
    color: #00b3b3;
    font-weight: bold;
}

.product-box .description,
.product-box .features,
.product-box .usage {
    text-align: left;
    color: #ddd;
}

.product-box .features li {
    color: #00b3b3;
}

.product-box img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 15px;
}

.usage {
    margin-top: 15px;
    background-color: #1f1f1f;
    border-radius: 5px;
    padding: 10px;
    color: #00ffff;
    text-align: left;
}

/* Estilos de la sección de chatbot */
.qa-section {
    margin-top: 50px;
    background-color: #242424;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 10px rgba(0, 255, 255, 0.1);
}

.qa-section h2 {
    margin-bottom: 20px;
    color: #00ffff;
    text-align: center;
}

.chatbot-box {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
    background-color: #1a1a1a;
    border: 1px solid #00ffff;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.chatbot-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.user-message {
    align-self: flex-end;
    background-color: #00ffff;
    color: #242424;
    border-radius: 15px;
    padding: 10px 15px;
    max-width: 70%;
    word-wrap: break-word;
    margin-bottom: 5px;
}

.bot-message {
    align-self: flex-start;
    background-color: #1f1f1f;
    border: 1px solid #00ffff;
    color: #ddd;
    border-radius: 15px;
    padding: 10px 15px;
    max-width: 70%;
    word-wrap: break-word;
    margin-bottom: 5px;
}

.typing-message {
    align-self: flex-start;
    background-color: #1f1f1f;
    color: #00ffff;
    border-radius: 15px;
    padding: 10px 15px;
    max-width: 70%;
    word-wrap: break-word;
    margin-bottom: 5px;
    font-style: italic;
}

.form-group {
    display: flex;
    margin-bottom: 0;
}

.form-control {
    flex-grow: 1;
    padding: 10px;
    border-radius: 15px;
    color: #ddd;
    margin-right: 10px;
}

#question:hover{
    color:  #00ffff;
}
#question::placeholder {
    color:  #00ffff;
}


.btn-cyan {
    background-color: #00ffff;
    color: #242424;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 15px;
}

.btn-cyan:hover {
    background-color: #00b3b3;
}

@media (max-width: 768px) {
    .product-box {
        width: 90%;
    }

    .qa-section {
        max-width: 100%;
    }

    .form-control {
        padding: 8px;
        font-size: 0.9em;
    }

    .btn-cyan {
        padding: 8px 15px;
    }
}
