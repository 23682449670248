/*Custom Css*/
h1 .text-success.text-center{
    background-color: transparent;
}

.span{color: aliceblue;}
#slick-slide00{
    width: 232px;
}

/*ESTILOS DE LA NAVEGACION DE EL MAPA LOCALIZACION*/

#map {
    height: 600px;
    width: 100%;
    border: 2px solid #00796b;
    border-radius: 10px;
    margin-bottom: 20px;
}
.controls {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.controls select, .controls button, .controls a {
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .controls select, .controls button, .controls a {
        width: 100%;
        margin-right: 0;
    }
}

select, button {
    padding: 10px;
    margin-right: 10px;
}
#wazeLink {
    display: none;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #ff6f20;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
}
#wazeLink:hover {
    background-color: #e65c00; 
}


.imagenes_carrusel{
    background-color: transparent;
    overflow-x: hidden;
}

.div-clases{
    width: 100%;
}
.div-color{
    background-color: rgb(14, 14, 13);
    border: 3px #b9fff9  solid;
}

.leaflet-control-zoom .leaflet-bar .leaflet-control{
    background-color: transparent;
}

/*Estilos de la deseos*/

.botones_deseos{
    background-color: transparent;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 3px solid #b9fff9 ;
}

.textos_de_elementos-cartas{
    color: white;
}

/*Paginacion*/

.pagination-container {
    white-space: nowrap;
    width: 100%;
}
.pagination-container ul {
    display: inline-flex;
}

/*Boton generar descripcion*/

.boton_descripcion{
    background-color: transparent;
    border:  3px solid #b9fff9;
    color: #b9fff9;
    border-radius: 20px;
}
.boton_descripcion:hover{
    background-color: #b9fff9;
    color: black;
}